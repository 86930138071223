import { Alert, AlertTitle, Container } from '@mui/material';
import { ADMINS } from 'config';
import useAuth from 'hooks/useAuth';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { PATH_AUTH } from 'routes/paths';

RoleBasedGuard.propTypes = {
  children: PropTypes.node,
};

function cleanEmail(email) {
  return email.replace(/\+.*@/, '@');
}

export default function RoleBasedGuard({ children }) {
  const { isAuthenticated, user } = useAuth();

  console.log('isAuthenticated', isAuthenticated);
  console.log('user', user);

  if (!isAuthenticated) {
    return <Navigate to={PATH_AUTH.login} />;
  }

  if (
    ADMINS.includes(cleanEmail(user.email)) ||
    user.email.endsWith('@beyondcash.io') ||
    process.env.NODE_ENV === 'development'
  ) {
    return <>{children}</>;
  }

  return (
    <Container>
      <Alert severity='error'>
        <AlertTitle>Permission Denied</AlertTitle>
        You do not have permission to access this page
      </Alert>
    </Container>
  );
}
