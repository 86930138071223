import SvgIconStyle from 'components/SvgIconStyle';
import { PATH_DASHBOARD } from 'routes/paths';

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
};

const sidebarConfig = [
  {
    title: 'Overview',
    path: PATH_DASHBOARD.overview,
    icon: ICONS.dashboard,
  },
  {
    title: 'Trade',
    path: PATH_DASHBOARD.trade,
    icon: ICONS.dashboard,
  },
  {
    title: 'Deals',
    path: PATH_DASHBOARD.deals,
    icon: ICONS.dashboard,
  },
  {
    title: 'Data Source',
    path: PATH_DASHBOARD.dataSource,
    icon: ICONS.dashboard,
  },
  {
    title: 'Notifications',
    path: PATH_DASHBOARD.notifications,
    icon: ICONS.dashboard,
    bottom: true,
  },
  {
    title: 'Settings',
    path: PATH_DASHBOARD.settings,
    icon: ICONS.dashboard,
    bottom: true,
  },
];

export default sidebarConfig;
