import { alpha } from '@mui/material/styles';

// SETUP COLORS
export const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#A2A3B1',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

export const PURPLE = {
  0: '#FFFFFF',
  100: '#F3F1F9',
  200: '#E0D9FA',
  300: '#C0B0FB',
  400: '#9377FD',
  500: '#6540ED',
  600: '#5E3CDF',
  700: '#5939CF',
  800: '#4C2FB6',
  900: '#40289A',
  1000: '#35217E',
};

export const BLUE = {
  0: '#FFFFFF',
  100: '#E4EDF9',
  200: '#D0E0F9',
  300: '#BBD4FA',
  400: '#A7C7FA',
  500: '#92BBFA',
  600: '#73AAFF',
  700: '#5999FB',
  800: '#4B83DA',
  900: '#396BB9',
  1000: '#2F5B9F',
};

export const GREEN = {
  0: '#FFFFFF',
  100: '#F5FCD2',
  200: '#F5FCD2',
  300: '#E8FAA6',
  400: '#D3F178',
  500: '#7AE8BB',
  600: '#78E4B8',
  700: '#6BD6AA',
  800: '#60BF98',
  900: '#4EA07E',
  1000: '#3D8064',
};

export const YELLOW = {
  0: '#FFFFFF',
  100: '#FAF3DD',
  200: '#FBECC0',
  300: '#FDE6A4',
  400: '#FEDF87',
  500: '#FFD96B',
  600: '#FFC73A',
  700: '#DBA32A',
  800: '#B7821D',
  900: '#936312',
  1000: '#7A4D0B',
};

export const ORANGE = {
  0: '#FFFFFF',
  100: '#F8EEE8',
  200: '#F7E3D7',
  300: '#F6D3C0',
  400: '#F4C4A9',
  500: '#F2AE87',
  600: '#FFA16B',
  700: '#FF853F',
  800: '#FC6A16',
  900: '#E25B0E',
  1000: '#B14507',
};

export const RED = {
  0: '#FFFFFF',
  100: '#F8E8E8',
  200: '#F7D7D7',
  300: '#F6C0C0',
  400: '#F4A9A9',
  500: '#F28787',
  600: '#FE6B6B',
  700: '#FF4646',
  800: '#F21818',
  900: '#DB0707',
  1000: '#B00000',
};

export const PINK = {
  0: '#FFFFFF',
  100: '#F8E8ED',
  200: '#F7D7E0',
  300: '#F6C0D0',
  400: '#F4A9C0',
  500: '#F287A7',
  600: '#FE6B97',
  700: '#FF467E',
  800: '#F2185A',
  900: '#DB0747',
  1000: '#B00035',
};

export const GREY_BLUE = {
  0: '#FFFFFF',
  100: '#EAEDF0',
  200: '#DBE2E8',
  300: '#CCD6DF',
  400: '#BECAD6',
  500: '#AFBFCE',
  600: '#A0B3C5',
  700: '#91A8BD',
  800: '#829CB4',
  900: '#7390AB',
  1000: '#6485A3',
};

export const GREY_DARK = {
  0: '#FFFFFF',
  100: '#878F9D',
  200: '#E0E9F4',
  300: '#C3D4E9',
  400: '#90A3BF',
  500: '#596780',
  600: '#1A202C',
  700: '#131825',
  800: '#0D121F',
  900: '#080C19',
  1000: '#32384A',
};

export const DARK = {
  0: '#FFFFFF',
  100: '#E0E9F4',
  200: '#E0E9F4',
  300: '#C3D4E9',
  400: '#90A3BF',
  500: '#596780',
  600: '#1A202C',
  700: '#131825',
  800: '#0D121F',
  900: '#080C19',
  1000: '#040815',
};
