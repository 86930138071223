function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_ADMIN = '/admin';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  forgotPassword: path(ROOTS_AUTH, '/forgot-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
  verifyCode: path(ROOTS_AUTH, '/verify-code'),
  verifyEmail: path(ROOTS_AUTH, '/verify-email'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
  privacyPolicy: '/privacy-policy',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  overview: path(ROOTS_DASHBOARD, '/overview'),
  trade: path(ROOTS_DASHBOARD, '/trade'),
  deals: path(ROOTS_DASHBOARD, '/deals'),
  dataSource: path(ROOTS_DASHBOARD, '/data-source'),
  notifications: path(ROOTS_DASHBOARD, '/notifications'),
  settings: path(ROOTS_DASHBOARD, '/settings'),
};

export const PATH_ADMIN = {
  root: ROOTS_ADMIN,
  overview: {
    root: path(ROOTS_ADMIN, '/overview'),
    dashboard: path(ROOTS_ADMIN, '/overview/dashboard'),
  },
  management: {
    root: path(ROOTS_ADMIN, '/management'),
    company: {
      list: path(ROOTS_ADMIN, '/management/companies'),
      detail: (id) => path(ROOTS_ADMIN, `/management/companies/${id}`),
    },
    transaction: {
      list: path(ROOTS_ADMIN, '/management/transactions'),
      detail: (id) => path(ROOTS_ADMIN, `/management/transactions/${id}`),
    },
    investor: {
      list: path(ROOTS_ADMIN, '/management/investors'),
      detail: (id) => path(ROOTS_ADMIN, `/management/investors/${id}`),
    },
  },
  offers: path(ROOTS_ADMIN, '/offers'),
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
export const ROOT_ONBOARDING = '/onboarding';
