import AuthGuard from 'guards/AuthGuard';
import GuestGuard from 'guards/GuestGuard';
import DashboardLayout from 'layouts/dashboard';
import AdminLayout from 'layouts/admin';
import LogoOnlyLayout from 'layouts/LogoOnlyLayout';
import MainLayout from 'layouts/main';
import OnboardingLayout from 'layouts/onboarding';
import { lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import RoleBasedGuard from 'guards/RoleBasedGuard';
import LoadingScreen from 'components/LoadingScreen';
import AuthLayout from 'layouts/AuthLayout';

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed',
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Auth Routes
    {
      path: 'auth',
      element: (
        <GuestGuard>
          <AuthLayout />
        </GuestGuard>
      ),
      children: [
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'verify-code', element: <VerifyCode /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify-email', element: <VerifyEmail /> },
      ],
    },

    // Onboarding Routes
    {
      path: 'onboarding',
      element: (
        <AuthGuard>
          <OnboardingLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'company-detail', element: <OnboardingCompanyDetails /> },
        { path: 'business-metric', element: <OnboardingBusinessMetrics /> },
        { path: 'data-source', element: <OnboardingDataSource /> },
        { path: 'review', element: <OnboardingReview /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to='/dashboard/trade' replace /> },
        { path: 'overview', element: <>Overview</> },
        { path: 'trade', element: <DashboardTrade /> },
        { path: 'deals', element: <DashboardDeals /> },
        { path: 'data-source', element: <DashboardDataSource /> },
        { path: 'notifications', element: <DashboardNotifications /> },
        { path: 'settings', element: <DashboardSettings /> },
      ],
    },

    // admin Routes
    {
      path: 'admin',
      element: (
        <RoleBasedGuard accessibleRoles={['admin']}>
          <AdminLayout />
        </RoleBasedGuard>
      ),
      children: [
        { element: <Navigate to='/admin/overview' replace /> },
        {
          path: 'overview',
          children: [
            { element: <Navigate to='/admin/overview/dashboard' replace /> },
            { path: 'dashboard', element: <AdminDashboard /> },
          ],
        },
        {
          path: 'management',
          children: [
            { element: <Navigate to='/admin/management/companies' replace /> },
            {
              path: 'companies',
              children: [{ element: <CompanyList /> }, { path: ':companyId', element: <CompanyDetails /> }],
            },
          ],
        },
        { path: 'offers', element: <Offers /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'offer', element: <Offer /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to='/404' replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <LandingPage /> },
        { path: 'privacy-policy', element: <PrivacyPolicy /> },
        {
          path: 'components',
          children: [
            { element: <ComponentsOverview /> },
            // FOUNDATIONS
            { path: 'color', element: <Color /> },
            { path: 'typography', element: <Typography /> },
            { path: 'shadows', element: <Shadows /> },
            { path: 'grid', element: <Grid /> },
            { path: 'icons', element: <Icons /> },
            // MATERIAL UI
            { path: 'accordion', element: <Accordion /> },
            { path: 'alert', element: <Alert /> },
            { path: 'autocomplete', element: <Autocomplete /> },
            { path: 'avatar', element: <Avatar /> },
            { path: 'badge', element: <Badge /> },
            { path: 'breadcrumbs', element: <Breadcrumb /> },
            { path: 'buttons', element: <Buttons /> },
            { path: 'checkbox', element: <Checkbox /> },
            { path: 'chip', element: <Chip /> },
            { path: 'dialog', element: <Dialog /> },
            { path: 'label', element: <Label /> },
            { path: 'list', element: <List /> },
            { path: 'menu', element: <Menu /> },
            { path: 'pagination', element: <Pagination /> },
            { path: 'pickers', element: <Pickers /> },
            { path: 'popover', element: <Popover /> },
            { path: 'progress', element: <Progress /> },
            { path: 'radio-button', element: <RadioButtons /> },
            { path: 'rating', element: <Rating /> },
            { path: 'slider', element: <Slider /> },
            { path: 'snackbar', element: <Snackbar /> },
            { path: 'stepper', element: <Stepper /> },
            { path: 'switch', element: <Switches /> },
            { path: 'table', element: <Table /> },
            { path: 'tabs', element: <Tabs /> },
            { path: 'textfield', element: <Textfield /> },
            { path: 'timeline', element: <Timeline /> },
            { path: 'tooltip', element: <Tooltip /> },
            { path: 'tree-view', element: <TreeView /> },
            { path: 'data-grid', element: <DataGrid /> },
            // EXTRA COMPONENTS
            { path: 'chart', element: <Charts /> },
            { path: 'map', element: <Map /> },
            { path: 'editor', element: <Editor /> },
            { path: 'copy-to-clipboard', element: <CopyToClipboard /> },
            { path: 'upload', element: <Upload /> },
            { path: 'carousel', element: <Carousel /> },
            { path: 'multi-language', element: <MultiLanguage /> },
            { path: 'animate', element: <Animate /> },
            { path: 'form-validation', element: <FormValidation /> },
          ],
        },
      ],
    },
    { path: '*', element: <Navigate to='/404' replace /> },
  ]);
}

// Authentication
const Login = Loadable(lazy(() => import('pages/authentication/Login')));
const Register = Loadable(lazy(() => import('pages/authentication/Register')));
const ForgotPassword = Loadable(lazy(() => import('pages/authentication/ForgotPassword')));
const VerifyCode = Loadable(lazy(() => import('pages/authentication/VerifyCode')));
const VerifyEmail = Loadable(lazy(() => import('pages/authentication/VerifyEmail')));
const NewPassword = Loadable(lazy(() => import('pages/authentication/NewPassword')));

// Onboarding
const OnboardingCompanyDetails = Loadable(lazy(() => import('pages/onboarding/OnboardingCompanyDetails')));
const OnboardingBusinessMetrics = Loadable(lazy(() => import('pages/onboarding/OnboardingBusinessMetrics')));
const OnboardingDataSource = Loadable(lazy(() => import('pages/onboarding/OnboardingDataSource')));
const OnboardingReview = Loadable(lazy(() => import('pages/onboarding/OnboardingReview')));

// Dashboard
const DashboardTrade = Loadable(lazy(() => import('pages/dashboard/DashboardTrade')));
const DashboardDeals = Loadable(lazy(() => import('pages/dashboard/DashboardDeals')));
const DashboardDataSource = Loadable(lazy(() => import('pages/dashboard/DashboardDataSource')));
const DashboardNotifications = Loadable(lazy(() => import('pages/dashboard/DashboardNotifications')));
const DashboardSettings = Loadable(lazy(() => import('pages/dashboard/DashboardSettings')));

// Admin
const AdminDashboard = Loadable(lazy(() => import('pages/admin/overview/OverviewDashboard')));
const CompanyList = Loadable(lazy(() => import('pages/admin/company/CompanyList')));
const CompanyDetails = Loadable(lazy(() => import('pages/admin/company/CompanyDetails')));
const Offers = Loadable(lazy(() => import('pages/admin/Offers')));

// Main
const LandingPage = Loadable(lazy(() => import('pages/LandingPage')));
const Offer = Loadable(lazy(() => import('pages/Offer')));
const PrivacyPolicy = Loadable(lazy(() => import('pages/PrivacyPolicy')));
const Maintenance = Loadable(lazy(() => import('pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('pages/Page500')));
const NotFound = Loadable(lazy(() => import('pages/Page404')));

// Components
const ComponentsOverview = Loadable(lazy(() => import('pages/ComponentsOverview')));
const Color = Loadable(lazy(() => import('pages/components-overview/foundations/FoundationColors')));
const Typography = Loadable(lazy(() => import('pages/components-overview/foundations/FoundationTypography')));
const Shadows = Loadable(lazy(() => import('pages/components-overview/foundations/FoundationShadows')));
const Grid = Loadable(lazy(() => import('pages/components-overview/foundations/FoundationGrid')));
const Icons = Loadable(lazy(() => import('pages/components-overview/foundations/FoundationIcons')));
const Accordion = Loadable(lazy(() => import('pages/components-overview/material-ui/Accordion')));
const Alert = Loadable(lazy(() => import('pages/components-overview/material-ui/Alert')));
const Autocomplete = Loadable(lazy(() => import('pages/components-overview/material-ui/Autocomplete')));
const Avatar = Loadable(lazy(() => import('pages/components-overview/material-ui/Avatar')));
const Badge = Loadable(lazy(() => import('pages/components-overview/material-ui/Badge')));
const Breadcrumb = Loadable(lazy(() => import('pages/components-overview/material-ui/Breadcrumb')));
const Buttons = Loadable(lazy(() => import('pages/components-overview/material-ui/buttons')));
const Checkbox = Loadable(lazy(() => import('pages/components-overview/material-ui/Checkboxes')));
const Chip = Loadable(lazy(() => import('pages/components-overview/material-ui/chips')));
const Dialog = Loadable(lazy(() => import('pages/components-overview/material-ui/dialog')));
const Label = Loadable(lazy(() => import('pages/components-overview/material-ui/Label')));
const List = Loadable(lazy(() => import('pages/components-overview/material-ui/Lists')));
const Menu = Loadable(lazy(() => import('pages/components-overview/material-ui/Menus')));
const Pagination = Loadable(lazy(() => import('pages/components-overview/material-ui/Pagination')));
const Pickers = Loadable(lazy(() => import('pages/components-overview/material-ui/pickers')));
const Popover = Loadable(lazy(() => import('pages/components-overview/material-ui/Popover')));
const Progress = Loadable(lazy(() => import('pages/components-overview/material-ui/progress')));
const RadioButtons = Loadable(lazy(() => import('pages/components-overview/material-ui/RadioButtons')));
const Rating = Loadable(lazy(() => import('pages/components-overview/material-ui/Rating')));
const Slider = Loadable(lazy(() => import('pages/components-overview/material-ui/Slider')));
const Snackbar = Loadable(lazy(() => import('pages/components-overview/material-ui/Snackbar')));
const Stepper = Loadable(lazy(() => import('pages/components-overview/material-ui/stepper')));
const Switches = Loadable(lazy(() => import('pages/components-overview/material-ui/Switches')));
const Table = Loadable(lazy(() => import('pages/components-overview/material-ui/table')));
const Tabs = Loadable(lazy(() => import('pages/components-overview/material-ui/Tabs')));
const Textfield = Loadable(lazy(() => import('pages/components-overview/material-ui/TextField')));
const Timeline = Loadable(lazy(() => import('pages/components-overview/material-ui/Timeline')));
const Tooltip = Loadable(lazy(() => import('pages/components-overview/material-ui/Tooltip')));
const TreeView = Loadable(lazy(() => import('pages/components-overview/material-ui/TreeView')));
const DataGrid = Loadable(lazy(() => import('pages/components-overview/material-ui/data-grid')));
//
const Charts = Loadable(lazy(() => import('pages/components-overview/extra/Charts')));
const Map = Loadable(lazy(() => import('pages/components-overview/extra/Map')));
const Editor = Loadable(lazy(() => import('pages/components-overview/extra/Editor')));
const CopyToClipboard = Loadable(lazy(() => import('pages/components-overview/extra/CopyToClipboard')));
const Upload = Loadable(lazy(() => import('pages/components-overview/extra/Upload')));
const Carousel = Loadable(lazy(() => import('pages/components-overview/extra/Carousel')));
const MultiLanguage = Loadable(lazy(() => import('pages/components-overview/extra/MultiLanguage')));
const Animate = Loadable(lazy(() => import('pages/components-overview/extra/animate')));
const FormValidation = Loadable(lazy(() => import('pages/components-overview/extra/form-validation')));
