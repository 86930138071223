export default function Autocomplete(theme) {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          padding: '5px 11px !important',
        },
        paper: {
          boxShadow: theme.customShadows.z20,
        },
      },
    },
  };
}
