import { createSlice } from '@reduxjs/toolkit';
import axios from 'utils/axios';

const initialState = {
  isLoading: false,
  error: false,
  list: [],
  selected: null,
  emailLog: {
    isLoaded: false,
    error: false,
    list: [],
  },
  manualUpload: {
    isLoading: false,
    error: false,
    list: [],
  },
};

const slice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getCompanyListSuccess(state, action) {
      const { companies } = action.payload;
      state.isLoading = false;
      state.list = companies;
    },

    getCompanySuccess(state, action) {
      state.isLoading = false;
      state.selected = action.payload;
    },

    startEmailLogLoading(state) {
      state.emailLog.isLoading = true;
    },

    hasEmailLogError(state, action) {
      state.emailLog.isLoading = false;
      state.emailLog.error = action.payload;
    },

    getEmailLogSuccess(state, action) {
      state.emailLog.isLoading = false;
      state.emailLog.list = action.payload;
    },

    startManualUploadsLoading(state) {
      state.manualUpload.isLoading = true;
    },

    hasManualUploadsError(state, action) {
      state.manualUpload.isLoading = false;
      state.manualUpload.error = action.payload;
    },

    getManualUploadsSuccess(state, action) {
      state.manualUpload.isLoading = false;
      state.manualUpload.list = action.payload;
    },
  },
});

export default slice.reducer;

export function getCompanyList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/companies');

      dispatch(slice.actions.getCompanyListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCompany(companyId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/companies/${companyId}`);

      dispatch(slice.actions.getCompanySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEmailLogs(userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startEmailLogLoading());
    try {
      const response = await axios.get(`/email/get-logs/${userId}`);

      dispatch(slice.actions.getEmailLogSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasEmailLogError(error));
    }
  };
}

export function getManualUploads() {
  return async (dispatch) => {
    dispatch(slice.actions.startManualUploadsLoading());
    try {
      const response = await axios.get(`/uploads`);

      dispatch(slice.actions.getManualUploadsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasManualUploadsError(error));
    }
  };
}
