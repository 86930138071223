import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const SubMenuTitleStyle = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  lineHeight: '32px',
  fontWeight: 400,
}));

const SubMenuPrefixStyle = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  lineHeight: '32px',
  fontWeight: 600,
}));

const menuConfig = [
  {
    title: 'Use Cases',
    to: 'landing-usecases',
  },
  {
    title: 'How it Works?',
    to: 'landing-how-it-works',
  },
  {
    title: 'Products',
    to: 'landing-products',
    children: [
      {
        title: (
          <SubMenuTitleStyle>
            <SubMenuPrefixStyle component='span'>Grow</SubMenuPrefixStyle>
            beyond
          </SubMenuTitleStyle>
        ),
        description: 'Accelerate your growth with dilution free funding.',
        to: 'landing-products',
        icon: '/static/icons/grow.svg',
      },
      {
        title: (
          <SubMenuTitleStyle>
            <SubMenuPrefixStyle component='span'>Earn</SubMenuPrefixStyle>
            beyond
          </SubMenuTitleStyle>
        ),
        description: 'Invest in "recurring revenue" and get up to 12% APR',
        to: 'landing-products',
        icon: '/static/icons/earn.svg',
      },
      {
        title: (
          <SubMenuTitleStyle>
            <SubMenuPrefixStyle component='span'>See</SubMenuPrefixStyle>
            beyond
          </SubMenuTitleStyle>
        ),
        description: 'Get free real time insights about your business.',
        to: 'landing-products',
        icon: '/static/icons/see.svg',
      },
    ],
  },
  {
    title: 'Integrations',
    to: 'landing-integrations',
  },
  {
    title: 'FAQ',
    to: 'landing-faq',
  },
];

export default menuConfig;
