import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Logo from 'components/Logo';
import PropTypes from 'prop-types';
import { Link as RouterLink, Outlet } from 'react-router-dom';

const RootStyle = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
}));

const LogoStyle = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 48,
  left: 112,
}));

const AuthOverlayStyle = styled(Box)(({ theme }) => ({
  zIndex: -1,
  position: 'absolute',
  left: '168px',
  top: '-99px',
  overflow: 'hidden',
  width: '1094px',
  height: '1094px',
  opacity: 0.32,
}));

AuthLayout.propTypes = {
  children: PropTypes.node,
};

export default function AuthLayout({ children }) {
  return (
    <RootStyle>
      <LogoStyle>
        <RouterLink to='/'>
          <Logo />
        </RouterLink>
      </LogoStyle>
      <AuthOverlayStyle
        component='img'
        alt='hero'
        src='/static/bg-overlay.png'
        srcSet={`/static/bg-overlay@2x.png 2x, /static/bg-overlay@3x.png 3x`}
      />
      <Outlet />
    </RootStyle>
  );
}
