import { Dialog } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { varFadeInUp } from './variants';

DialogAnimate.propTypes = {
  open: PropTypes.bool.isRequired,
  animate: PropTypes.object,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default function DialogAnimate({ open = false, animate, onClose, children, ...other }) {
  const theme = useTheme();

  return (
    <AnimatePresence>
      {open && (
        <Dialog
          fullWidth
          maxWidth='xs'
          open={open}
          onClose={onClose}
          PaperComponent={motion.div}
          PaperProps={{
            sx: {
              borderRadius: 2,
              bgcolor: '#0C101C',
              [theme.breakpoints.up('md')]: {
                ...(other.paperwidth && { minWidth: other.paperwidth }),
              },
            },
            ...(animate || varFadeInUp),
          }}
          {...other}
        >
          {children}
        </Dialog>
      )}
    </AnimatePresence>
  );
}
