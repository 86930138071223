import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import arrowIosUpwardFill from '@iconify/icons-eva/arrow-ios-upward-fill';
import { Icon } from '@iconify/react';
import { Backdrop, Box, Grid, Link, Popover, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

const LinkStyle = styled(Link)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.primary,
  marginRight: theme.spacing(5),
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shortest,
  }),
  fontSize: '16px',
  fontWeight: 600,

  '&:hover': {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
  },
}));

const ScrollLinkStyle = styled(ScrollLink)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.primary,
  marginRight: theme.spacing(5),
  cursor: 'pointer',
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shortest,
  }),
  fontSize: '16px',
  fontWeight: 600,

  '&:hover': {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
  },
}));

IconBullet.propTypes = {
  type: PropTypes.oneOf(['subheader', 'item']),
};

function IconBullet({ type = 'item' }) {
  return (
    <Box sx={{ width: 24, height: 16, display: 'flex', alignItems: 'center' }}>
      <Box
        component='span'
        sx={{
          ml: '2px',
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'currentColor',
          ...(type !== 'item' && {
            ml: 0,
            width: 8,
            height: 2,
            borderRadius: 2,
          }),
        }}
      />
    </Box>
  );
}

MenuDesktopItem.propTypes = {
  item: PropTypes.object,
  isHome: PropTypes.bool,
  isOffset: PropTypes.bool,
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

function MenuDesktopItem({ item, isHome, isOpen, isOffset, onOpen, onClose }) {
  const { title, path, to, children } = item;

  if (children) {
    return (
      <>
        <Backdrop
          sx={{
            zIndex: -1,
            backdropFilter: 'blur(12px)',
            WebkitBackdropFilter: 'blur(12px)', // Fix on Mobile
          }}
          open={isOpen}
          onClick={onClose}
        />

        <LinkStyle
          onClick={onOpen}
          sx={{
            display: 'flex',
            cursor: 'pointer',
            alignItems: 'center',
            ...(isHome && { color: 'common.white' }),
            ...(isOffset && { color: 'text.primary' }),
            ...(isOpen && { color: 'secondary.main' }),
          }}
        >
          {title}
          <Box
            component={Icon}
            icon={isOpen ? arrowIosUpwardFill : arrowIosDownwardFill}
            sx={{ ml: 0.5, width: 16, height: 16 }}
          />
        </LinkStyle>

        <Popover
          open={isOpen}
          anchorReference='anchorPosition'
          anchorPosition={{ top: 117, left: 0 }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={onClose}
          PaperProps={{
            sx: {
              px: 3,
              pt: 5,
              pb: 3,
              right: 16,
              m: 'auto',
              p: (theme) => theme.spacing(5),
              borderRadius: 2,
              maxWidth: 840,
              backgroundColor: '#0B0E17',
              boxShadow: (theme) => theme.customShadows.z24,
            },
          }}
        >
          <Grid container spacing={10}>
            {children.map((item) => (
              <Grid item xs={12} sm={6} md={4} key={item.title}>
                <ScrollLink to={item.to} spy smooth offset={50} onClick={onClose}>
                  <Stack direction='column' sx={{ '&:hover': { cursor: 'pointer' } }}>
                    {item.icon && (
                      <Box
                        component='img'
                        src={item.icon}
                        alt={item.title}
                        sx={{
                          width: 40,
                          height: 40,
                          fontSize: '20px',
                          lineHeight: '32px',
                        }}
                        mb={2}
                      />
                    )}
                    {item.title}
                    <Typography
                      sx={{
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: 'text_color.cadet_grey',
                      }}
                    >
                      {item.description}
                    </Typography>
                  </Stack>
                </ScrollLink>
              </Grid>
            ))}
          </Grid>
        </Popover>
      </>
    );
  }

  if (to && !children) {
    return (
      <ScrollLinkStyle to={to} spy smooth offset={-100}>
        {title}
      </ScrollLinkStyle>
    );
  } else {
    return (
      <LinkStyle
        to={path}
        component={RouterLink}
        end={path === '/'}
        sx={{
          ...(isHome && { color: 'common.white' }),
          ...(isOffset && { color: 'text.primary' }),
          '&.active': {
            color: 'primary.main',
          },
        }}
      >
        {title}
      </LinkStyle>
    );
  }
}

MenuDesktop.propTypes = {
  isOffset: PropTypes.bool,
  isHome: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function MenuDesktop({ isOffset, isHome, navConfig }) {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Stack direction='row'>
      {navConfig.map((link) => (
        <MenuDesktopItem
          key={link.title}
          item={link}
          isOpen={open}
          onOpen={handleOpen}
          onClose={handleClose}
          isOffset={isOffset}
          isHome={isHome}
        />
      ))}
    </Stack>
  );
}
