import { combineReducers } from 'redux';
// import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import adminReducer from './slices/admin';
import onboardingReducer from './slices/onboarding';
import companyReducer from './slices/company';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  onboarding: onboardingReducer,
  admin: adminReducer,
  company: companyReducer,
});

export { rootPersistConfig, rootReducer };
