import { BLUE, DARK, GREEN, GREY, GREY_BLUE, GREY_DARK, ORANGE, PINK, PURPLE, RED, YELLOW } from './colors';

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

const PRIMARY = {
  lighter: '#6239EC',
  light: '#6239EC',
  main: '#6D61EC',
  dark: '#6239EC',
  darker: '#6239EC',
  contrastText: '#fff',
};
const SECONDARY = {
  lighter: '#96E5BE',
  light: '#96E5BE',
  main: '#96E5BE',
  dark: '#96E5BE',
  darker: '#96E5BE',
  contrastText: '#fff',
};
const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff',
};
const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: GREY[800],
};
const WARNING = {
  lighter: '#FFF7CD',
  light: '#ECC095',
  main: '#ECC095',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800],
};
const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff',
};

const PRIMARY_COLORS = {
  medium_slate_blue: '#6239EC',
  turkuaz: '#96E5BE',
  jordy_blue: '#92BAFB',
  melrose: '#CABDF9',
  white: '#FFFFFF',
  orange: '#ECC095',
  crocus: '#9278E5',
};

const BACKGROUND_COLORS = {
  cinder: '#0B0E17',
  ebony: '#080721',
  ebony_clay: '#21293B',
  tuna: '#313A4B',
  mirage: '#0B0E17',
  default: '#0D1321',
  disable_bg: '#1C212E',
};

const TEXT_COLORS = {
  river_bed: '#475267',
  cadet_grey: '#94A3B6',
  santa_grey: '#A2A3B1',
  grey_cloud: '#B2B5C2',
  white: '#B2B5C2',
};

const GRADIENTS = {
  default: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CUSTOM_GRADIENTS = {
  default: 'linear-gradient(119.64deg, #6239EC 0.67%, #92BBFA 92.95%)',
  hover: 'linear-gradient(119.64deg, #815BFF 0.67%, #A9CBFF 92.95%)',
  press: 'linear-gradient(119.64deg, #582DE8 0.67%, #5A9BFF 92.95%)',
  coolness_of_nobility: 'radial-gradient(151.13% 265.19% at 133.03% -46.25%, #653CEC 0%, #CCC0F9 55.73%, #AEC0F6 100%)',
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  // colors
  grey: GREY,
  purple: PURPLE,
  blue: BLUE,
  green: GREEN,
  yellow: YELLOW,
  orange: ORANGE,
  red: RED,
  pink: PINK,
  grey_blue: GREY_BLUE,
  grey_dark: GREY_DARK,
  dark: DARK,
  primary_color: PRIMARY_COLORS,
  custom_gradient: CUSTOM_GRADIENTS,
  text_color: TEXT_COLORS,
  background_color: BACKGROUND_COLORS,
  action: {
    hover: '#9278E5',
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: '#9278E5',
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette = {
  light: {
    ...COMMON,
    text: { primary: GREY[800], secondary: GREY[400], disabled: GREY[500] },
    background: { paper: '#fff', default: '#fff', neutral: GREY[200] },
    action: { active: GREY[600], ...COMMON.action },
  },
  dark: {
    ...COMMON,
    text: { primary: '#fff', secondary: '#A2A3B1', disabled: GREY[600] },
    background: { paper: GREY[800], default: '#0C101C', neutral: GREY[500_16] },
    action: { active: GREY[500], ...COMMON.action },
  },
};

export default palette;
