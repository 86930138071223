import { Box, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';

const ListItemStyle = styled(ListItemButton)(({ theme }) => ({
  ...theme.typography.body2,
  height: 56,
  position: 'relative',
  textTransform: 'capitalize',
  padding: '0 28px',
  color: theme.palette.text.secondary,
  '&:before': {
    top: 'calc(50% - 12px)',
    right: 0,
    width: 8,
    height: 24,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: '#96E5BE',
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 24,
  height: 24,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '12px',
});

NavItem.propTypes = {
  isShow: PropTypes.bool,
  item: PropTypes.object,
};

function NavItem({ item, isShow }) {
  const { pathname } = useLocation();
  const { title, path, icon, info } = item;
  const isActiveRoot = path ? !!matchPath({ path, end: false }, pathname) : false;

  const activeRootStyle = {
    color: 'common.white',
    fontWeight: 'fontWeightMedium',
    '&:before': { display: 'block' },
  };

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      {isShow && (
        <>
          <ListItemText disableTypography primary={title} />
          {info && info}
        </>
      )}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  isShow: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, isShow = true, ...other }) {
  return (
    <Box {...other}>
      {navConfig.map((item) => {
        return <NavItem key={item.title} item={item} isShow={isShow} />;
      })}
    </Box>
  );
}
