import { Box, Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import useAuth from 'hooks/useAuth';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { setStep } from 'redux/slices/onboarding';
import OnboardingSidebar from './OnboardingSidebar';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
}));

const MainStyle = styled(Card)(({ theme }) => ({
  display: 'flex',
  borderRadius: theme.spacing(2),
  backgroundColor: '#161A2A',
  margin: theme.spacing(9, 8, 9, 0),
}));

const getStepIndex = (step) => steps.findIndex((item) => item.key === step);

const getStepOptions = (step) => {
  const index = getStepIndex(step);

  return {
    active: step,
    next: steps[index + 1]?.key,
    prev: steps[index - 1]?.key,
    isFirst: step === steps[0].key,
    isLast: step === steps[steps.length - 1].key,
  };
};

export default function Onboarding() {
  const { company } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const shouldGoToStep = pathname === '/onboarding';
  const requestedStep = pathname.split('/').pop();

  useEffect(() => {
    // if user requested a step that is a forward step, redirect to the current step
    // || getStepIndex(requestedStep) > getStepIndex(company.onboardingStep
    if (shouldGoToStep) {
      navigate(`/onboarding/${company.onboardingStep}`);
    }

    if (company.onboardingStep === 'review') {
      navigate('/onboarding/review');
    }
  }, [company.onboardingStep, navigate, requestedStep, shouldGoToStep]);

  useEffect(() => {
    dispatch(setStep(getStepOptions(requestedStep)));
  }, [dispatch, requestedStep]);

  return (
    <RootStyle>
      <OnboardingSidebar steps={steps} activeStep={getStepIndex(requestedStep)} />
      <Box sx={{ flexGrow: 1 }} />
      <MainStyle>
        <Box px={12} py={7} sx={{ height: '100%', width: 952, position: 'relative' }}>
          <Outlet />
        </Box>
      </MainStyle>
    </RootStyle>
  );
}

const steps = [
  {
    label: 'Company Info',
    key: 'company-detail',
    description:
      'Share the fundamental details of your company here. This information helps investors get a better understanding of your business. Ensure the data you provide is accurate and up-to-date.',
  },
  {
    label: 'Business Metrics',
    key: 'business-metric',
    description:
      'Provide the core business metrics that allow us to assess your financial performance and present it to potential investors. This data is crucial for determining the value and investment potential of your company.',
  },
  {
    label: 'Data Source',
    key: 'data-source',
    description:
      'Securely connect your banking, accounting and subscription tools to access your offer and draw your capital in hours.',
  },
  {
    label: 'Review',
    key: 'review',
    description: `We are reviewing the information you've submitted. Our verification process ensures that the details are reliable and accurate. Please wait for approval.`,
  },
];
