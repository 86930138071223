export default function Timeline(theme) {
  return {
    MuiTimelineDot: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderColor: theme.palette.primary_color.melrose,
        },
      },
    },

    MuiTimelineConnector: {
      styleOverrides: {
        root: {
          backgroundColor: '#2D334A !important',
        },
      },
    },

    MuiTimelineItem: {
      styleOverrides: {
        root: {
          '&:before': { display: 'none' },
        },
      },
    },
  };
}
