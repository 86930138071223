import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Label from 'components/Label';
import PropTypes from 'prop-types';

const LogoStyle = styled(Typography)(({ theme }) => ({
  color: '#fff',
  fontWeight: 900,
  fontSize: '20px',
  marginLeft: '10px',
  position: 'absolute',
  right: 0,
  top: 0,
}));

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ includeText = false, includeVersion = true, sx }) {
  if (includeText) {
    return (
      <Stack
        direction='row'
        alignItems='center'
        justifyContent={{
          xs: 'unset',
          md: 'unset',
        }}
        sx={{
          position: 'relative',
          minWidth: '160px',
          width: 160,
        }}
      >
        <Box component='img' alt='logo' src='/static/brand/logo_full.svg' sx={{ width: 32, height: 32, ...sx }} />
        {includeText && <LogoStyle>beyondcash</LogoStyle>}
        {includeVersion && (
          <Label color='info' sx={{ ml: 1, fontSize: '10px', position: 'absolute', bottom: -16, right: -2 }}>
            beta
          </Label>
        )}
      </Stack>
    );
  } else {
    return <Box component='img' alt='logo' src='/static/brand/logo_full.svg' sx={{ width: 48, height: 48, ...sx }} />;
  }
}
