import useAuth from 'hooks/useAuth';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { PATH_AUTH, ROOT_ONBOARDING } from 'routes/paths';

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { pathname } = useLocation();
  const { isAuthenticated, isVerified, company } = useAuth();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const isOnboarding = pathname.startsWith('/onboarding');

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Navigate to={PATH_AUTH.login} />;
  }

  if (isAuthenticated && !isVerified) {
    return <Navigate to={PATH_AUTH.verifyEmail} />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  if (company.status === 'pending-onboarding' && !isOnboarding) {
    return <Navigate to={ROOT_ONBOARDING} />;
  }

  return <>{children}</>;
}
