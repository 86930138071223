import { SvgIcon, IconButton, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { InfoCircle, CloseCircle } from 'iconsax-react';

const BTextField = ({ label, tooltipText, errorText, ...other }) => {
  const renderLabel = () => {
    if (tooltipText) {
      return (
        <Stack direction='row' alignItems='center'>
          <Typography variant=''>{label}</Typography>
          <Tooltip title={tooltipText} placement='top'>
            <IconButton sx={{ p: 0, transform: 'scale(0.6)' }}>
              <InfoCircle size='24' />
            </IconButton>
          </Tooltip>
        </Stack>
      );
    } else {
      return label;
    }
  };

  const renderError = () => {
    return (
      <Stack direction='row' alignItems='center'>
        <SvgIcon color='error' sx={{ width: 16, height: 16, marginRight: 1 }}>
          <CloseCircle size='24' />
        </SvgIcon>
        <Typography variant='caption'>{errorText}</Typography>
      </Stack>
    );
  };

  return (
    <TextField
      type='text'
      variant='outlined'
      label={renderLabel()}
      {...other}
      {...(errorText && { helperText: renderError() })}
      InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
    />
  );
};

export default BTextField;
