export default function Stepper(theme) {
  return {
    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderColor: theme.palette.divider,
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        text: {
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: 500,
        },
      },
    },
  };
}
