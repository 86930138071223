import { createSlice } from '@reduxjs/toolkit';
import axios from 'utils/axios';

const initialState = {
  isLoading: false,
  error: false,
  step: {
    active: null,
    next: null,
    isFirst: false,
    isLast: false,
  },
  companyDetail: {
    name: '',
    website: '',
    industry: '',
    country: '',
    address: '',
  },
  businessMetric: {
    recurringRevenue: '',
    growthRate: '',
    runway: '',
  },
  dataSource: {
    open: false,
    source: null,
    integration: {},
    linked: [],
    uploads: [],
  },
};

const slice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setStep(state, action) {
      state.step = action.payload;
    },

    setSource(state, action) {
      state.dataSource.source = action.payload;
      state.dataSource.open = true;
    },

    setIntegration(state, action) {
      state.dataSource.integration = action.payload;
    },

    unsetSource(state, action) {
      state.dataSource.source = null;
      state.dataSource.integration = {};
      state.dataSource.open = false;
      state.dataSource.uploads = [];
    },

    unsetIntegration(state, action) {
      state.dataSource.integration = {};
      state.dataSource.uploads = [];
    },

    getCompanyDetailsSuccess(state, action) {
      state.isLoading = false;
      state.companyDetail = action.payload;
    },

    updateCompanyDetailsSuccess(state, action) {
      state.isLoading = false;
    },

    getBusinessMetricsSuccess(state, action) {
      state.isLoading = false;
      state.businessMetric = action.payload;
    },

    updateBusinessMetricsSuccess(state, action) {
      state.isLoading = false;
    },

    getDataSourcesSuccess(state, action) {
      state.isLoading = false;
      state.dataSource.linked = action.payload;
    },

    getDataSourceUploadsSuccess(state, action) {
      state.isLoading = false;
      state.dataSource.uploads = action.payload;
    },
  },
});

export default slice.reducer;

export const { setStep, setSource, setIntegration, unsetSource, unsetIntegration } = slice.actions;

export function getCompanyDetails(companyId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/companies/${companyId}`);

      dispatch(slice.actions.getCompanyDetailsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateCompanyDetails(companyId, data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/companies/${companyId}`, data);

      dispatch(slice.actions.updateCompanyDetailsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBusinessMetrics(companyId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/companies/${companyId}/business-metric`);

      dispatch(slice.actions.getBusinessMetricsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateBusinessMetrics(companyId, data, step) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.put(`/companies/${companyId}/business-metric`, data);
      await axios.put(`/companies/${companyId}`, { onboardingStep: step });

      dispatch(slice.actions.updateBusinessMetricsSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDataSources(companyId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/data-sources`);

      dispatch(slice.actions.getDataSourcesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDataSourceUploads(sourceType) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/uploads/${sourceType}`);

      dispatch(slice.actions.getDataSourceUploadsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
