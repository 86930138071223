import { AppBar, Box, Button, Container, Link, Stack, Toolbar, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { MHidden } from 'components/@material-extend';
import Logo from 'components/Logo';
import useOffSetTop from 'hooks/useOffSetTop';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { PATH_AUTH } from 'routes/paths';
import navConfig from './MenuConfig';
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';

const APP_BAR_DESKTOP = 144;
const APP_BAR_MOBILE = 72;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_DESKTOP,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),

  [theme.breakpoints.down('md')]: {
    height: APP_BAR_MOBILE,
  },
}));

export default function MainNavbar() {
  const theme = useTheme();
  const { pathname } = useLocation();
  const isOffset = useOffSetTop(100);
  const isHome = pathname === '/';
  const isMobile = useMediaQuery(theme.breakpoints.down('1100px'));

  return (
    <AppBar
      sx={{ zIndex: 12, boxShadow: 0, backgroundColor: 'transparent' }}
      position={isMobile ? 'relative' : 'absolute'}
    >
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: '#0C101B',
            height: { md: APP_BAR_DESKTOP - 16 },
          }),
        }}
      >
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <Link component={RouterLink} to='/'>
            <Logo includeText />
          </Link>

          <Box sx={{ flexGrow: 1 }} />

          <MHidden width='mdDown'>
            <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />

            <Stack direction='row' spacing={1}>
              <Button
                variant='contained'
                size='medium'
                sx={{ width: 140 }}
                component={RouterLink}
                to={PATH_AUTH.register}
              >
                Sign Up
              </Button>
              <Button variant='outlined' size='medium' sx={{ width: 140 }} component={RouterLink} to={PATH_AUTH.login}>
                Login
              </Button>
            </Stack>
          </MHidden>

          <MHidden width='mdUp'>
            <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>
        </Container>
      </ToolbarStyle>
    </AppBar>
  );
}
