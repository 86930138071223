import menu2Fill from '@iconify/icons-eva/menu-fill';
import { Icon } from '@iconify/react';
import { Button, Drawer, Link, List, ListItemButton, ListItemText, Stack } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { MIconButton } from 'components/@material-extend';
import Logo from 'components/Logo';
import Scrollbar from 'components/Scrollbar';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

const ITEM_SIZE = 48;
const PADDING = 2.5;

const ListItemStyle = styled(ListItemButton)(({ theme }) => ({
  ...theme.typography.body2,
  height: ITEM_SIZE,
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(PADDING),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
}));

MenuMobileItem.propTypes = {
  item: PropTypes.object,
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
};

function MenuMobileItem({ item, isOpen, onOpen, isLast, handleDrawerClose }) {
  const { title, to } = item;

  return (
    <ListItemStyle
      sx={{
        boxSizing: 'initial',
        '&.active': {
          color: 'primary.main',
          fontWeight: 'fontWeightMedium',
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
        },
      }}
    >
      <ScrollLink to={to} smooth offset={-100} onClick={handleDrawerClose}>
        <ListItemText
          disableTypography
          primary={title}
          sx={{
            color: '#fff',
            fontSize: '20px',
            lineHeight: '26px',
            fontWeight: 600,
            py: 2,

            ...(!isLast && {
              borderBottom: '1px solid #21293B',
            }),
          }}
        />
      </ScrollLink>
    </ListItemStyle>
  );
}

MenuMobile.propTypes = {
  isOffset: PropTypes.bool,
  isHome: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function MenuMobile({ isOffset, isHome, navConfig }) {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    if (drawerOpen) {
      handleDrawerClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <>
      <MIconButton
        onClick={handleDrawerOpen}
        sx={{
          ml: 1,
          ...(isHome && { color: 'common.white' }),
          ...(isOffset && { color: 'text.primary' }),
        }}
      >
        <Icon icon={menu2Fill} color='#94A3B6' />
      </MIconButton>

      <Drawer
        open={drawerOpen}
        onClose={handleDrawerClose}
        ModalProps={{ keepMounted: true }}
        PaperProps={{ sx: { pb: 5, width: 260, backgroundColor: '#0C101C' } }}
      >
        <Scrollbar>
          <Link component={RouterLink} to='/' sx={{ display: 'inline-flex' }}>
            <Logo includeText sx={{ ml: PADDING, my: 3 }} />
          </Link>

          <List disablePadding>
            {navConfig.map((link, idx) => (
              <MenuMobileItem
                key={link.title}
                item={link}
                isOpen={open}
                onOpen={handleOpen}
                handleDrawerClose={handleDrawerClose}
                isLast={idx + 1 === navConfig.length}
              />
            ))}
          </List>

          <Stack direction='column' spacing={2} px='20px' mt={5}>
            <Button variant='contained'>Sign Up</Button>
            <Button variant='outlined'>Login</Button>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
