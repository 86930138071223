import { createSlice } from '@reduxjs/toolkit';
import axios from 'utils/axios';

const initialState = {
  companyStatuses: {
    isLoading: false,
    error: false,
    data: {},
  },
};

const slice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    startCompanyStatusesLoading(state) {
      state.companyStatuses.isLoading = true;
    },

    hasCompanyStatusesError(state, action) {
      state.companyStatuses.isLoading = false;
      state.companyStatuses.error = action.payload;
    },

    getCompanyStatusesSuccess(state, action) {
      state.companyStatuses.isLoading = false;
      state.companyStatuses.data = action.payload.reduce((acc, curr) => {
        const status = curr.status;
        if (!acc[status]) {
          acc[status] = 0;
        }
        acc[status] += 1;
        return acc;
      }, {});
    },
  },
});

export default slice.reducer;

export function getCompanyStatus() {
  return async (dispatch) => {
    dispatch(slice.actions.startCompanyStatusesLoading());
    try {
      const response = await axios.get('/companies');

      dispatch(slice.actions.getCompanyStatusesSuccess(response.data?.companies || []));
    } catch (error) {
      dispatch(slice.actions.hasCompanyStatusesError(error));
    }
  };
}
