import { Card, Link, Stack, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

const RootStyle = styled(Card)(({ theme }) => ({
  width: 272,
  height: 72,
  borderRadius: 0,
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: theme.palette.background_color.ebony_clay,
  '&:after': {
    zIndex: -1,
    position: 'absolute',
    right: 0,
    bottom: -6,
    content: 'url(/static/oval.png)',
  },
}));

export default function HelpCard() {
  const theme = useTheme();

  return (
    <RootStyle>
      <Stack direction='column' sx={{ px: 3, py: 2 }}>
        <Typography variant='body2' color='text.common' sx={{ fontWeight: theme.typography.fontWeightSemiBold }}>
          Do you still need our help?
        </Typography>
        <Typography variant='caption' color='grey_blue.300'>
          Send your request via{' '}
          <Link href='mailto:hello@beyondcash.io?subject=What%20is%20it%20all%20about?' sx={{ color: '#9278E5' }}>
            e-mail
          </Link>
        </Typography>
      </Stack>
    </RootStyle>
  );
}
