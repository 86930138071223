import { Box, Button, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { varFadeInUp } from 'components/animate';
import HelpCard from 'components/HelpCard';
import Logo from 'components/Logo';
import { motion } from 'framer-motion';
import useAuth from 'hooks/useAuth';
import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: theme.spacing(34),
  margin: theme.spacing(7, 8),
}));

const HeroOverlayStyle = styled(motion.img)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: -1,
  height: '100%',
  overflow: 'hidden',
}));

OnboardingSidebar.propTypes = {
  steps: PropTypes.array,
};

export default function OnboardingSidebar({ steps, activeStep }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <RootStyle>
      <HeroOverlayStyle
        alt='hero'
        src='/static/bg-overlay.png'
        variants={varFadeInUp}
        srcSet={`/static/bg-overlay@2x.png 2x, /static/bg-overlay@3x.png 3x`}
      />

      <Box component={RouterLink} to='/' sx={{ display: 'inline-flex' }}>
        <Logo />
      </Box>

      <Box mt={6}>
        <Stepper activeStep={activeStep} orientation='vertical'>
          {steps.map((step, index) => (
            <Step key={step.label} sx={{ width: theme.spacing(27) }}>
              <StepLabel
                StepIconProps={{
                  sx: {
                    width: 30,
                    height: 30,
                    marginRight: theme.spacing(1),
                    color: theme.palette.background_color.tuna,
                  },
                }}
              >
                <Typography variant='subtitle1'>{step.label}</Typography>
              </StepLabel>
              <StepContent sx={{ paddingLeft: '30px' }}>
                <Typography variant='caption' color='text_color.cadet_grey'>
                  {step.description}
                </Typography>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>

      <Box sx={{ flexGrow: 1 }} />

      <HelpCard />
      <Button onClick={handleLogout}>Logout</Button>
    </RootStyle>
  );
}
