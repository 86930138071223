import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.data.success === false) {
      return Promise.reject('Something went wrong');
    } else if (response.data.success === true) {
      return response.data;
    } else {
      throw new Error('Something went wrong');
    }
  },
  (error) =>
    Promise.reject(
      (error.response && typeof error.response.data.message === 'string' && new Error(error.response.data.message)) ||
        new Error('Something went wrong'),
    ),
);

export default axiosInstance;
