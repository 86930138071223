import { Box, GlobalStyles } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CloseCircle, InfoCircle, TickCircle, Warning2 } from 'iconsax-react';
import { SnackbarProvider } from 'notistack';
import PropTypes from 'prop-types';

function SnackbarStyles() {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  return (
    <GlobalStyles
      styles={{
        '#root': {
          '& .SnackbarContent-root': {
            width: 480,
            padding: theme.spacing(1.625, 2),
            margin: theme.spacing(0.25, 0),
            boxShadow: theme.customShadows.z8,
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.grey[isLight ? 900 : 0],
            '&.SnackbarItem-variantSuccess': {
              color: theme.palette.background_color.cinder,
              backgroundColor: theme.palette.secondary.main,
            },
            '&.SnackbarItem-variantWarning': {
              color: theme.palette.background_color.cinder,
              backgroundColor: theme.palette.primary_color.orange,
            },
            '&.SnackbarItem-variantInfo': {
              color: theme.palette.text.primary,
              backgroundColor: '#858BA4',
            },
            '&.SnackbarItem-variantError': {
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.red[600],
            },
          },
          '& .SnackbarItem-message': {
            padding: '0 !important',
            fontWeight: theme.typography.fontWeightSemiBold,
          },
          '& .SnackbarItem-action': {
            marginRight: 0,
            color: theme.palette.action.active,
            '& svg': { width: 20, height: 20 },
          },
        },
      }}
    />
  );
}

SnackbarIcon.propTypes = {
  icon: PropTypes.object,
  color: PropTypes.string,
};

function SnackbarIcon({ icon, color }) {
  return (
    <Box
      component='span'
      sx={{
        mr: 1,
        width: 20,
        height: 20,
        display: 'flex',
        borderRadius: 1.5,
        alignItems: 'center',
        justifyContent: 'center',
        color: (theme) =>
          ['success', 'warning'].includes(color) ? theme.palette.background_color.cinder : 'text.primary',
      }}
    >
      {icon}
    </Box>
  );
}

NotistackProvider.propTypes = {
  children: PropTypes.node,
};

export default function NotistackProvider({ children }) {
  return (
    <>
      <SnackbarStyles />

      <SnackbarProvider
        dense
        maxSnack={5}
        // preventDuplicate
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        iconVariant={{
          success: <SnackbarIcon icon={<TickCircle size='20' />} color='success' />,
          error: <SnackbarIcon icon={<CloseCircle size='20' />} color='error' />,
          warning: <SnackbarIcon icon={<Warning2 size='20' />} color='warning' />,
          info: <SnackbarIcon icon={<InfoCircle size='20' />} color='info' />,
        }}
      >
        {children}
      </SnackbarProvider>
    </>
  );
}
