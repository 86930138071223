export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: 'none',
          },
          '&.Mui-disabled': {
            backgroundImage: 'unset',
            backgroundColor: '#313A4B',
            color: '#575C6F',
          },
        },
        sizeSmall: {
          height: 40,
        },
        sizeMedium: {
          height: 48,
        },
        sizeLarge: {
          height: 56,
        },
        // contained
        containedInherit: {
          color: theme.palette.grey[800],
          '&:hover': {
            backgroundColor: theme.palette.grey[400],
          },
        },
        containedPrimary: {
          backgroundImage: theme.palette.custom_gradient.default,
          '&:hover': {
            backgroundImage: theme.palette.custom_gradient.hover,
          },
          '&:active': {
            backgroundImage: theme.palette.custom_gradient.press,
          },
        },
        containedSecondary: {
          backgroundColor: '#7C5CFC',
          '&:hover': {
            backgroundColor: '#6239EC',
          },
          '&:active': {
            backgroundColor: '#40289A',
          },
        },
        containedInfo: {},
        containedSuccess: {},
        containedWarning: {},
        containedError: {},
        // outlined
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        outlinedPrimary: {
          border: '1px solid rgba(146, 187, 250, 0.5)',
          color: '#fff',
          '&:hover': {
            backgroundImage: theme.palette.custom_gradient.hover,
          },
          '&:active': {
            backgroundImage: theme.palette.custom_gradient.press,
          },
        },
        outlinedSecondary: {
          borderColor: '#7C5CFC',
          color: '#fff',
          '&:hover': {
            backgroundColor: '#6239EC',
            borderColor: '#7C5CFC',
          },
          '&:active': {
            backgroundColor: '#40289A',
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        text: {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
        startIcon: {
          marginRight: '10px',
        },
      },
    },
  };
}
