import useAuth from 'hooks/useAuth';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { PATH_DASHBOARD, PATH_PAGE, ROOT_ONBOARDING } from 'routes/paths';

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const { isAuthenticated, isVerified, company } = useAuth();

  if (isAuthenticated && isVerified) {
    if (company.status === 'pending-onboarding') {
      return <Navigate to={ROOT_ONBOARDING} />;
    } else if (company.status === 'approved') {
      return <Navigate to={PATH_DASHBOARD.root} />;
    } else {
      return <Navigate to={PATH_PAGE.page404} />;
    }
  }

  return <>{children}</>;
}
