import useAuth from '../hooks/useAuth';
import createAvatar from 'utils/createAvatar';
import { MAvatar } from './@material-extend';

export default function MyAvatar({ ...other }) {
  const { user } = useAuth();
  const fullName = `${user.firstName} ${user.lastName}`;

  return (
    <MAvatar
      src={user.photoURL}
      alt={fullName}
      color={user.photoURL ? 'default' : createAvatar(fullName).color}
      {...other}
    >
      {createAvatar(fullName).name}
    </MAvatar>
  );
}
