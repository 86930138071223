export default function Switch(theme) {
  return {
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: '9px 11px',
        },
        thumb: {
          width: 16,
          height: 16,
          boxShadow: theme.customShadows.z1,
        },
        track: {
          opacity: 1,
          backgroundColor: '#313A4B',
          borderRadius: '12px',
        },
        switchBase: {
          left: 0,
          right: 'auto',
          color: '#94A3B6',
          padding: '11.3px 15px',
          '&.Mui-checked': {
            color: 'white',
            padding: '11.5px 8px',
          },
          '&.Mui-checked+.MuiSwitch-track': {
            opacity: 1,
            backgroundColor: '#96E5BE',
          },
        },
      },
    },
  };
}

// export default function Switch(theme) {
//   const isLight = theme.palette.mode === 'light';

//   return {
//     MuiSwitch: {
//       styleOverrides: {
//         root: {
//           height: '20px',
//           padding: 0,
//         },
//         thumb: {
//           boxShadow: theme.customShadows.z1,
//         },
//         track: {
//           opacity: 1,
//           backgroundColor: theme.palette.background_color.tuna,
//         },
//         switchBase: {
//           left: 0,
//           right: 'auto',
//           padding: 0,
//           '&.Mui-checked': {
//             color: 'white',
//           },
//           '&:not(:.Mui-checked)': {
//             color: theme.palette.grey[isLight ? 100 : 300],
//           },
//           '&.Mui-checked.Mui-disabled, &.Mui-disabled': {
//             color: theme.palette.grey[isLight ? 400 : 600],
//           },
//           '&.Mui-disabled+.MuiSwitch-track': {
//             opacity: 1,
//             backgroundColor: `${theme.palette.action.disabledBackground} !important`,
//           },
//         },
//       },
//     },
//   };
// }
