import { Box, Button, Container, Divider, Grid, Link, Stack, TextField, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Logo from 'components/Logo';
import { Link as RouterLink } from 'react-router-dom';
import { PATH_PAGE } from 'routes/paths';
import { MHidden } from 'components/@material-extend';

const RootStyle = styled('div')(({ theme }) => ({
  // margin: theme.spacing(8, 0),
  [theme.breakpoints.down('md')]: {
    padding: '4px',
  },
}));

const LINKS = [
  { name: 'Privacy Policy', href: PATH_PAGE.privacyPolicy },
  { name: 'Contact Us', href: 'mailto:hello@beyondcash.io?subject=Beyondcash', external: true },
];

const FooterNav = () => {
  const theme = useTheme();

  return (
    <Stack direction={{ xs: 'column', lg: 'row' }} justifyContent='space-between'>
      <Stack spacing={2} direction='row' divider={<Divider orientation='vertical' flexItem />} alignItems='center'>
        {LINKS.map((link) => (
          <Link
            key={link.name}
            color='inherit'
            variant='caption'
            sx={{
              display: 'block',
              color: 'text_color.cadet_grey',
            }}
            {...(link.external
              ? {
                  href: link.href,
                }
              : { component: RouterLink, to: link.href })}
          >
            {link.name}
          </Link>
        ))}
      </Stack>
      <MHidden width='mdDown'>
        <Box sx={{ flexGrow: 1 }} />
      </MHidden>
      <Typography
        sx={{
          fontSize: '12px',
          lineHeight: '16px',
          fontWeight: 400,
          color: '#94A3B6',

          [theme.breakpoints.down('md')]: {
            fontSize: '14px',
            lineHeight: '24px',
            marginTop: '24px',
          },
        }}
      >
        © 2022 Beyondcash Inc. All rights reserved.
      </Typography>
    </Stack>
  );
};

export default function MainFooter() {
  const theme = useTheme();

  return (
    <RootStyle
      sx={{
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Container maxWidth='lg'>
        <Box
          sx={{
            margin: { xs: '48px 0', lg: '64px 0' },
            display: 'flex',
            alignItems: 'center',
            justifyContent: { xs: 'flex-start', lg: 'space-between' },
          }}
        >
          <Box>
            <Logo includeText disabled />
            <Typography
              sx={{
                fontSize: '16px',
                lineHeight: '24px',
                fontWeight: 400,
                maxWidth: 320,
                marginTop: '32px',

                [theme.breakpoints.down('md')]: {
                  marginTop: '24px',
                  maxWidth: 'unset',
                },
              }}
            >
              Design amazing digital experiences that create more happy in the world.
            </Typography>
          </Box>
          <MHidden width='mdDown'>
            <Stack direction='column' spacing={2}>
              <Typography
                sx={{
                  fontSize: '16px',
                  lineHeight: '24px',
                  fontWeight: 400,
                }}
              >
                Get the freshest news from us
              </Typography>
              <Grid container>
                <Grid item>
                  <TextField variant='outlined' placeholder='Your email address…' sx={{ width: '272px' }} />
                </Grid>

                <Grid item alignItems='stretch' style={{ display: 'flex' }}>
                  <Button
                    color='primary'
                    variant='contained'
                    sx={{
                      width: '120px',
                      marginLeft: '8px',
                      fontSize: '14px',
                      lineHeight: '24px',
                      fontWeight: 400,
                    }}
                  >
                    Subscribe
                  </Button>
                </Grid>
              </Grid>
            </Stack>
          </MHidden>
        </Box>
      </Container>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: { xs: 'unset', lg: '120px' },
          backgroundColor: '#101828',
          padding: { xs: '32px 0', lg: '0' },
        }}
      >
        <Container>
          <FooterNav />
        </Container>
      </Box>
    </RootStyle>
  );
}
